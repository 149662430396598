<template>
    <div class="targets-content">
        <div class="target-title-card">
            <img :src="getImgUrl(props.urlImage)" alt="add-user-icon">
            <h3 class="target-title">{{ props.title }}</h3>
        </div>

        <div class="target-chart-container">
            <div class="chart-progress-container-currentMonth">
                <div class="subtitle-content">
                    <p class="target-description">Meta</p>
                    <p class="target-description">Supermeta</p>
                </div>
                <apexchart height="16px" class="chartBar" type="bar" :options="optionsProgressTarget"
                    :series="seriesProgressTarget">
                </apexchart>
                <div class="values-content-target">
                    <p class="target-value">0</p>
                    <p class="target-value target-middle">{{props.target}}</p>
                    <p class="target-value">{{props.superTarget}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    valueCurrent:{
        type:Number,
        required:false
    },
    target: {
        type: Number,
        required: true,
    },
    superTarget: {
        type: Number,
        required: true,
    },
    urlImage: {
        type: String,
        required: true
    },

});

const limitMaxProgress = (valueCurrent) =>{
    if(valueCurrent <  props.superTarget){
        return props.superTarget
    }else{
        return valueCurrent
    }
}

const getImgUrl = (img) => {
    var images = require.context(
        "../../../../../assets/targets/",
        false,
        /\.svg$/
    );
    return images("./" + img + ".svg");
}

const seriesProgressTarget = ref([{
    name: 'Valor Atual',
    data: [props.valueCurrent || 0],
}])

const optionsProgressTarget = ref({
    chart: {
        height: 13,
        type: "bar",
        stacked: true,
        sparkline: {
            enabled: true,
        },
        
    },
    plotOptions: {
        bar: {
            horizontal: true,
            borderRadius: 6,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all',
            barHeight: "100%",
            colors: {
                backgroundBarColors: ["#E3E3E1"],
                backgroundBarRadius: 6,

            },
            dataLabels: {
                position: 'top',
            },
        }
    },
    dataLabels: {
        enabled: true,
        offsetX: -7,
        offsetY: 1,
        style: {
            fontSize: '9px',
            colors: ['#fff']
        }
    },

    colors: ["#696969"],

    stroke: {
        show: true,
        width: 0,
        colors: ['transparent']
    },

    tooltip: {
        enabled: true,
        fixed: {
          enabled: true,
          position: 'bottomLeft',
          offsetX: 3,
          offsetY: -3,
      },
    },
    xaxis: {
        categories: [props.title]
    },
    yaxis: {
        max:limitMaxProgress(props.valueCurrent || 0)
    },
    fill: {
        opacity: 1,
    }
})

</script>

<style lang="scss" scoped>
@import "./ModalTarget.scss";
</style>